import React, { useEffect, useState, useMemo } from 'react';
import I18n from '../i18n';
import { XAxis, YAxis, Bar, CartesianGrid, Tooltip, BarChart, LineChart, Line, ResponsiveContainer } from 'recharts';
import './dashboard.scss';
import { useFleets, useOperatingModes, useVehicles } from '../fetchers/fleet';
import { DataPoint, EnergyEarnings, VehicleStatus } from '@solar-data/schemas/lib/owned-by/solar';
import { useEnergyEarnings } from '../fetchers/energy';
import { useVehicleStatus } from '../fetchers/vehicleStatus';
import moment from 'moment';
import { useParams } from 'react-router';
import { SelectedDayProvider, useSelectedDay } from '../components/selectedDay';
import DailyEnergyEarnings from '../components/dailyEnergyEarnings';
import InfoBox from '../components/infoBox';
import { useWeather } from '../fetchers/weather';
import { IsGranted } from '../privateRoute';
import { ROLE } from '../constants/roles';

type Range = [moment.Moment | undefined, moment.Moment | undefined];

function formatRange(range: Range): string {
  if (range[0] === undefined || range[1] === undefined) {
    return '';
  } else if (range[0].month() === range[1].month()) {
    return range[0].format('MMMM YYYY');
  } else {
    return range[0].format('YYYY');
  }
}

function kWh(kWh: number | undefined): string {
  if (kWh === undefined) {
    return '-';
  }
  return kWh.toFixed(2).replace(/\.?0*$/, '');
}

function getDefaultTimeRange(timeMode: string): Range {
  switch (timeMode) {
    case 'all-time':
      return [undefined, undefined];
    case 'year':
      return [moment().startOf('year'), moment().endOf('year')];
    default:
      let defaultMonth: moment.Moment;
      // as we only get energy data for the day before so we show the previous month on the first day of every new month.
      // So that customers won't see an empty graph.
      if (moment().date() === 1) defaultMonth = moment().subtract({ months: 1 });
      else defaultMonth = moment();

      return [defaultMonth.startOf('month'), defaultMonth.clone().endOf('month')];
  }
}

/**
 * JSX Element representing a block with summary values.
 */
function Tile({
  color,
  title,
  range,
  at,
  value,
  unit,
  img,
}: {
  color: 'night' | 'sun';
  title: string;
  range: Range;
  at?: moment.Moment;
  value?: number;
  unit: string;
  img?: string;
}) {
  return (
    <div className={`tile ${color}`}>
      {img ? <img src={img} alt="" /> : null}
      {I18n.t(title)}
      <div className="timestamp">{at ? at.format('ll') : formatRange(range)}</div>
      <div className="number">
        {value === undefined || value === null ? '-' : value >= 100 ? value.toFixed(0) : value.toFixed(1)}
      </div>
      {I18n.t(unit)}
    </div>
  );
}

function OperatingModesSelection({
  includeOperatingModes,
  setIncludeOperatingModes,
}: {
  includeOperatingModes: number[];
  setIncludeOperatingModes: (val: number[]) => void;
}) {
  const { operatingModes } = useOperatingModes();
  const [dropdown, setDropdown] = useState(false);

  const refs = useMemo(() => operatingModes.map(() => React.createRef<HTMLInputElement>()), [operatingModes]);

  return (
    <div className="operating-modes-selection">
      <span onClick={() => setDropdown(!dropdown)}>
        <span className="label">{I18n.t('solar.dashboard.includedOperatingModes')}</span>
        {' ' + (includeOperatingModes.map((iom) => operatingModes.find((om) => om.id === iom)?.name).join(', ') || '-')}
      </span>
      <span className="selector">
        <span onClick={() => setDropdown(!dropdown)} className="arrow">
          ▼
        </span>
        {dropdown ? (
          <ul className="dropdown">
            {operatingModes.map((om, idx) => (
              <li key={om.id}>
                <input
                  ref={refs[idx]}
                  defaultChecked={includeOperatingModes.includes(om.id)}
                  type="checkbox"
                  id={`om-${om.id}`}
                />{' '}
                <label htmlFor={`om-${om.id}`}>{om.name}</label>
              </li>
            ))}
            <li>
              <button
                onClick={() => {
                  setIncludeOperatingModes(
                    operatingModes.filter((om, idx) => refs[idx].current?.checked).map((om) => om.id),
                  );
                  setDropdown(false);
                }}
              >
                {I18n.t('solar.ok')}
              </button>
              <button onClick={() => setDropdown(false)}>{I18n.t('solar.cancel')}</button>
            </li>
          </ul>
        ) : null}
      </span>
    </div>
  );
}

function ChartTooltip({
  payload,
  includedOperatingModes,
}: {
  payload?: any;
  includedOperatingModes: number[] | undefined;
}) {
  const { operatingModes } = useOperatingModes();

  if (payload && payload.length > 0 && payload[0].payload) {
    const dp = payload[0].payload as DataPoint;
    const om = operatingModes?.find((om) => om.id === dp.operatingMode);
    const isIncluded = !includedOperatingModes || !om || includedOperatingModes.includes(om.id);

    return (
      <div className="tooltip">
        <div>{moment(dp.ts, 'YYYY-MM-DD').isValid() ? moment(dp.ts, 'YYYY-MM-DD').format('ll') : dp.ts}</div>
        <div>
          {isIncluded || dp.energykWh > 0 ? (
            <>
              {I18n.t('solar.dashboard.energy')}: {kWh(dp.energykWh)} kWh
            </>
          ) : (
            I18n.t('solar.dashboard.excluded')
          )}
        </div>
        <div>
          <span className="operatingModeIcon" style={{ backgroundColor: om?.color ?? 'white' }} /> {om?.name ?? '-'}
        </div>
      </div>
    );
  }
  return null;
}

function DemoDisclaimer() {
  return (
    <InfoBox title={I18n.t('solar.dashboard.demoDisclaimer.title')}>
      {I18n.t('solar.dashboard.demoDisclaimer.text')
        .split('\n')
        .map((line: string, idx: number) => (
          <div key={idx}>{line}</div>
        ))}
    </InfoBox>
  );
}

/**
 * JSX Element representing the summary view including energy earnings graph over all vehicles.
 */
function Summary({
  selectedVehicle,
  energyEarnings,
  onRangeSelected,
  range,
  includeOperatingModes,
  setIncludeOperatingModes,
}: {
  selectedVehicle: number | undefined;
  energyEarnings: EnergyEarnings | undefined;
  onRangeSelected: (range: Range) => void;
  range: Range;
  includeOperatingModes: number[];
  setIncludeOperatingModes: (val: number[]) => void;
}) {
  const { currentFleet } = useFleets();
  const { vehicles } = useVehicles();

  const { setSelectedDay } = useSelectedDay();
  const { operatingModes } = useOperatingModes();
  const defaultTimeMode = 'month';

  // set default time mode to month so that only data for the current month is loaded
  const [timeMode, setTimeMode] = useState(defaultTimeMode);

  // introduced to be able to control the "default" time range when the timeMode changes.
  // "defaultValue" of <select> did not work here as we need to change the value after the initial state.
  const [selectedRange, setSelectedRange] = useState(undefined as string | undefined);
  const firstDayOfFleet = moment.min(vehicles.filter((v) => v.firstDay).map((v) => moment(v.firstDay, 'YYYY-MM-DD')));
  const minTs = firstDayOfFleet.format('YYYY-MM-DD');
  const maxTs = moment().format('YYYY-MM-DD');

  const csvDataUrl = useMemo(() => {
    if (energyEarnings && energyEarnings.timeline) {
      const csv = [
        [I18n.t('solar.dashboard.downloadCsv.day'), I18n.t('solar.dashboard.downloadCsv.energyEarningsWh')],
        ...energyEarnings.timeline.map((dp) => [dp.ts, Math.round(dp.energykWh * 1000)]),
      ]
        .map((line) => line.join(';'))
        .join('\n');

      return `data:text/csv;base64,${Buffer.from(csv, 'utf-8').toString('base64')}`;
    }
    return undefined;
  }, [energyEarnings]);

  const csvDataFile = `Sono Solar Data - ${currentFleet}${
    selectedVehicle ? ' - ' + vehicles.find((v) => v.id === selectedVehicle)?.name : ''
  } - ${formatRange(range)}.csv`;

  let timeRanges: [timeMode: string, from: moment.Moment | undefined, to: moment.Moment | undefined][];
  switch (timeMode) {
    case 'all-time':
      timeRanges = [];
      timeRanges.push(['n/a', undefined, undefined]);
      break;
    case 'year':
      timeRanges = [];
      for (
        let y = moment(minTs, 'YYYY-MM-DD').startOf('year');
        !y.isAfter(moment(maxTs, 'YYYY-MM-DD'));
        y = y.add({ years: 1 })
      ) {
        timeRanges.push([y.format('YYYY'), y.clone(), y.clone().endOf('year')]);
      }
      break;
    default:
      timeRanges = [];
      for (
        let y = moment(minTs, 'YYYY-MM-DD').startOf('month');
        !y.isAfter(moment(maxTs, 'YYYY-MM-DD'));
        y = y.add({ months: 1 })
      ) {
        timeRanges.push([y.format('MMMM YYYY'), y.clone(), y.clone().endOf('month')]);
      }
      break;
  }

  // update the time range when the time mode changes
  useEffect(() => {
    let defaultTimeRange = getDefaultTimeRange(timeMode);
    let formattedDefaultTimeRange = formatRange(getDefaultTimeRange(timeMode));
    if (formattedDefaultTimeRange === '') formattedDefaultTimeRange = 'n/a';
    setSelectedRange(formattedDefaultTimeRange);
    onRangeSelected(defaultTimeRange);
  }, [timeMode, onRangeSelected]);

  return (
    <>
      <h1>
        {selectedVehicle ? vehicles.find((v) => v.id === selectedVehicle)?.name : I18n.t('solar.dashboard.summary')}
      </h1>
      {currentFleet === 'demo' ? <DemoDisclaimer /> : null}
      <div className="summary">
        <div>
          <OperatingModesSelection
            includeOperatingModes={includeOperatingModes}
            setIncludeOperatingModes={setIncludeOperatingModes}
          />
          <div className="tiles">
            <Tile
              color="night"
              title="solar.dashboard.totalEnergyEarnings"
              range={range}
              value={energyEarnings?.totalkWh}
              unit="solar.dashboard.kWh"
              img="sun.png"
            />
            <div className="column">
              <Tile
                color="sun"
                range={range}
                unit="solar.dashboard.kWh"
                {...(timeMode === 'month'
                  ? {
                      title: 'solar.dashboard.weeklyAverageEnergyEarnings',
                      value: energyEarnings?.weeklyAveragekWh,
                    }
                  : {
                      title: 'solar.dashboard.monthlyAverageEnergyEarnings',
                      value: energyEarnings?.monthlyAveragekWh,
                    })}
              />
              <Tile
                color="sun"
                title="solar.dashboard.dailyAverageEnergyEarnings"
                range={range}
                value={energyEarnings?.dailyAveragekWh}
                unit="solar.dashboard.kWh"
              />
            </div>
            <div className="column">
              <Tile
                color="sun"
                title="solar.dashboard.peakEnergyEarnings"
                range={range}
                at={energyEarnings?.peakTs ? moment(energyEarnings?.peakTs, 'YYYY-MM-DD') : undefined}
                value={energyEarnings?.peakkWh}
                unit="solar.dashboard.kWh"
              />
              <Tile
                color="sun"
                title="solar.dashboard.dailyAverageUsage"
                range={range}
                value={energyEarnings?.dailyAverageUsageHours}
                unit="solar.dashboard.hours"
              />
            </div>
          </div>
        </div>
        <div className="column">
          <div className="durationPicker">
            <span>{I18n.t('solar.dashboard.duration.title')}</span>
            <select onChange={(e) => setTimeMode(e.target.value)} defaultValue={defaultTimeMode}>
              <option value="all-time">{I18n.t('solar.dashboard.duration.allTime')}</option>
              <option value="year">{I18n.t('solar.dashboard.duration.year')}</option>
              <option value="month">{I18n.t('solar.dashboard.duration.month')}</option>
            </select>
            <select
              onChange={(e) => {
                onRangeSelected(
                  (timeRanges.find((to) => to[0] === e.target.value)?.slice(1) as [
                    moment.Moment | undefined,
                    moment.Moment | undefined,
                  ]) ?? [undefined, undefined],
                );
                setSelectedRange(e.target.value);
              }}
              value={selectedRange}
            >
              {timeRanges.map(([label]) => (
                <option key={label}>{label}</option>
              ))}
            </select>
            <a className="download" download={csvDataFile} href={csvDataUrl ?? ''}>
              <img
                src="download.png"
                alt={I18n.t('solar.dashboard.downloadCsv.label')}
                title={I18n.t('solar.dashboard.downloadCsv.tooltip')}
              />
            </a>
          </div>
          <div className="chart">
            <ResponsiveContainer width="100%" aspect={600 / 380}>
              <BarChart data={energyEarnings?.timeline ?? []}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                  dataKey="ts"
                  tickFormatter={(t) => (moment(t, 'YYYY-MM-DD').isValid() ? moment(t, 'YYYY-MM-DD').format('ll') : t)}
                />
                <YAxis unit=" kWh" width={90} />
                <Tooltip content={<ChartTooltip includedOperatingModes={includeOperatingModes} />} />
                <Bar
                  onClick={(dp) => setSelectedDay(dp.ts)}
                  dataKey="energykWh"
                  fill="#fbba00"
                  background={(props) => (
                    <rect
                      key={props.x}
                      x={props.x - 1}
                      y={props.y}
                      width={props.width + 2}
                      height={props.height}
                      fill={operatingModes.find((om) => om.id === props.payload.operatingMode)?.color ?? 'transparent'}
                    />
                  )}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </>
  );
}

function Aggregate({
  img,
  value,
  label,
  unit,
}: {
  img: string;
  value: string | number | undefined;
  label: string;
  unit?: string;
}) {
  return (
    <div className="aggregate">
      <img src={img} alt="" />
      <span className="value">
        {value === undefined ? '-' : value}
        {unit ? ` ${unit}` : null}
      </span>
      <span className="label">{I18n.t(label)}</span>
    </div>
  );
}

/**
 * JSX Element representing the vehicles overview table.
 */
function AllVehicles({ vehicleStatus, range }: { vehicleStatus: VehicleStatus[] | undefined; range: Range }) {
  const { currentFleet } = useFleets();
  const [search, setSearch] = useState('');

  return (
    <>
      <h1>{I18n.t('solar.dashboard.allVehicles.title')}</h1>
      <div className="allVehicles">
        <div className="aggregates">
          <Aggregate img="vehicles.png" value={vehicleStatus?.length} label="solar.dashboard.allVehicles.vehicles" />
          <Aggregate
            img="capacity.png"
            value={vehicleStatus?.reduce((total, vs) => total + vs.installedCapacitykW, 0)}
            label="solar.dashboard.allVehicles.kWpInstalled"
          />
        </div>
        <table>
          <thead className="no-mobile">
            <tr>
              <th>
                <input
                  placeholder={I18n.t('solar.dashboard.allVehicles.typeToSearch')}
                  onChange={(e) => setSearch(e.target.value.trim().toLowerCase())}
                />
              </th>
              <th>{I18n.t('solar.dashboard.allVehicles.latestSignal')}</th>
              <th>{I18n.t('solar.dashboard.allVehicles.installedCapacity')}</th>
              <th>
                {I18n.t('solar.dashboard.allVehicles.energyEarnings')}
                <div className="range">{formatRange(range)}</div>
              </th>
              <th />
            </tr>
          </thead>
          <tbody>
            {vehicleStatus && vehicleStatus.length > 0 ? (
              vehicleStatus
                .filter((vs) => vs.name.toLowerCase().includes(search))
                .map((vs) => (
                  <tr key={vs.id}>
                    <td>{vs.name}</td>
                    <td
                      className="no-mobile"
                      title={vs.latestSignal ? moment(vs.latestSignal).format('lll') : undefined}
                    >
                      {vs.latestSignal ? moment(vs.latestSignal).format('ll') : '-'}
                    </td>
                    <td className="no-mobile">{vs.installedCapacitykW} kWp</td>
                    <td className="no-mobile">{kWh(vs.energyEarningskWh)} kWh</td>
                    <IsGranted roles={[ROLE.CAN_VIEW_VEHICLE_DETAILS]}>
                      <td>
                        <a href={`#/${currentFleet}/details/${vs.id}`} className="button">
                          {I18n.t('solar.dashboard.allVehicles.viewDetails')}
                        </a>
                      </td>
                    </IsGranted>
                  </tr>
                ))
            ) : (
              <tr>
                <td>-</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </>
  );
}

function Sun(props: any) {
  console.log(props);
  return null;
}

function HourlyOutput({ min, max, vehicleId }: { min?: string; max?: string; vehicleId?: number }) {
  const energy = useEnergyEarnings();
  const { currentFleet } = useFleets();
  const { vehicles } = useVehicles();
  const { selectedDay, setSelectedDay } = useSelectedDay();

  const { weather, clearWeather, setLocation } = useWeather();

  useEffect(() => {
    clearWeather();
    if (selectedDay && currentFleet && vehicleId) {
      const vehicle = vehicles.find((v) => v.id === vehicleId);
      const md = moment(selectedDay, 'YYYY-MM-DD');
      energy.query(currentFleet, vehicleId, md, md, 'hour', undefined);
      if (vehicle?.location && vehicle.timeZone) {
        setLocation(vehicle.location.latitude, vehicle.location.longitude, selectedDay, vehicle.timeZone);
      }
    }
  }, [selectedDay, vehicleId, currentFleet, vehicles]);

  const sunriseX = weather
    ? Math.round((weather.sunrise.diff(weather.sunrise.clone().startOf('day'), 'hours') / 24) * 100)
    : undefined;
  const sunsetX = weather
    ? Math.round((weather.sunset.diff(weather.sunrise.clone().startOf('day'), 'hours') / 24) * 100)
    : undefined;

  return (
    <div className="diagram hourlyOutput">
      <h2>{I18n.t('solar.dashboard.details.hourlyOutput')}</h2>
      <div className="dayPicker">
        <label>{I18n.t('solar.dashboard.details.hourlyOutputDay')}</label>
        <input
          onChange={(e) => setSelectedDay(e.target.value)}
          value={selectedDay ?? ''}
          type="date"
          min={min}
          max={max}
        />
      </div>
      <div className="chart">
        {weather ? (
          <div className="weather">
            <img src={weather.icon} />
            <span className="temperature">{weather.temperatureC}°C</span>
            <span className="description">{weather.description}</span>
          </div>
        ) : null}
        <ResponsiveContainer width="100%" aspect={600 / 340}>
          <LineChart data={energy.queryResult?.timeline}>
            {sunriseX && sunsetX ? (
              <defs>
                <linearGradient id="sunGradient">
                  <stop offset="0%" stopColor="rgba(0, 22, 36, 0.5)" />
                  <stop offset={`${sunriseX - 2}%`} stopColor="rgba(0, 22, 36, 0.5)" />
                  <stop offset={`${sunriseX + 2}%`} stopColor="rgba(0, 22, 36, 0)" />
                  <stop offset={`${sunsetX - 2}%`} stopColor="rgba(0, 22, 36, 0)" />
                  <stop offset={`${sunsetX + 2}%`} stopColor="rgba(0, 22, 36, 0.5)" />
                  <stop offset="100%" stopColor="rgba(0, 22, 36, 0.5)" />
                </linearGradient>
              </defs>
            ) : null}
            <CartesianGrid strokeDasharray="3 3" fill="url(#sunGradient)" />
            <XAxis dataKey="ts" tickFormatter={(value: any) => `${value}`.substring(0, 5)} />
            <YAxis unit=" kW" width={90} />
            <Tooltip
              labelFormatter={(value: any) => `${value}`.substring(0, 5)}
              formatter={(value: number) => [`${kWh(value)} kW`, I18n.t('solar.dashboard.avgPower')]}
            />
            <Line dataKey="energykWh" stroke="#fbba00" />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

/**
 * JSX Element representing the detail view including the chart for a single vehicle.
 */
function VehicleDetails({
  vehicleStatus,
  energyEarnings,
  range,
}: {
  vehicleStatus: VehicleStatus | undefined;
  energyEarnings: EnergyEarnings | undefined;
  range: Range;
}) {
  return (
    <>
      <h1>{I18n.t('solar.dashboard.details.title')}</h1>
      <div className="details">
        <div className="aggregates">
          <Aggregate
            img="status.png"
            value={vehicleStatus?.latestSignal ? moment(vehicleStatus.latestSignal).format('ll') : undefined}
            label="solar.dashboard.details.latestSignal"
          />
          <Aggregate
            img="capacity.png"
            value={vehicleStatus?.installedCapacitykW}
            label="solar.dashboard.details.kWpInstalled"
          />
        </div>
        <div className="diagrams">
          {vehicleStatus && energyEarnings ? (
            <DailyEnergyEarnings vehicleStatus={vehicleStatus} energyEarnings={energyEarnings} />
          ) : null}
          <HourlyOutput
            min={energyEarnings?.timeline[0]?.ts}
            max={energyEarnings?.timeline[energyEarnings?.timeline.length - 1]?.ts}
            vehicleId={vehicleStatus?.id}
          />
        </div>
      </div>
    </>
  );
}

/**
 * JSX Element representing the landing page for a fleet.
 */
export default function Dashboard() {
  const params = useParams();
  const { currentFleet } = useFleets();
  const currentVehicleId = params.vehicle ? Number.parseInt(params.vehicle) : undefined;

  const vehicleStatus = useVehicleStatus();
  const energyEarnings = useEnergyEarnings();
  const [includeOperatingModes, setIncludeOperatingModes] = useState([] as number[]);
  const { operatingModes } = useOperatingModes();

  useEffect(() => {
    if (operatingModes) {
      const storedOperatingModes = (localStorage.getItem(`selectedOperatingModes-${currentFleet}`) ?? '')
        .split(',')
        .map((o) => Number.parseInt(o))
        .filter((id) => operatingModes.find((om) => om.id === id));

      if (storedOperatingModes.length === 0) {
        setIncludeOperatingModes(operatingModes.filter((om) => om.useForStatistics).map((om) => om.id));
      } else {
        setIncludeOperatingModes(storedOperatingModes);
      }
    }
  }, [operatingModes, currentFleet]);

  useEffect(() => {
    if (includeOperatingModes.length > 0) {
      localStorage.setItem(`selectedOperatingModes-${currentFleet}`, includeOperatingModes.join(','));
    }
  }, [includeOperatingModes]);

  // set default time mode to month so that only data for the current month is loaded
  const defaultRange = getDefaultTimeRange('month');
  const [range, setRange] = useState(defaultRange);

  useEffect(() => {
    if (currentFleet) {
      energyEarnings.query(currentFleet, currentVehicleId, range[0], range[1], 'day', includeOperatingModes);
    }
  }, [currentFleet, currentVehicleId, range, includeOperatingModes]);

  useEffect(() => {
    if (currentFleet) {
      vehicleStatus.query(currentFleet, range[0], range[1]);
    }
  }, [currentFleet, range, includeOperatingModes]);

  if (currentFleet === 'admin') {
    return (
      <div className="cards">
        <div className="card">
          <h2>Admin fleet</h2>
          <p>This is a special fleet that contains no vehicles but only users.</p>
          <p>Users who are added to this fleet will have access to all other fleets.</p>
          <p>
            <a href="#/admin/users" className="button">
              Manage admin users
            </a>
          </p>
        </div>
      </div>
    );
  }

  return (
    <SelectedDayProvider>
      <div className="dashboard">
        {currentVehicleId !== undefined ? (
          <a className="back" href={`#/${currentFleet}`}>
            {I18n.t('solar.dashboard.backToAllVehicles')}
          </a>
        ) : null}
        <Summary
          includeOperatingModes={includeOperatingModes}
          setIncludeOperatingModes={setIncludeOperatingModes}
          selectedVehicle={currentVehicleId}
          energyEarnings={energyEarnings.queryResult}
          onRangeSelected={setRange}
          range={range}
        />
        {currentVehicleId !== undefined ? (
          <VehicleDetails
            vehicleStatus={vehicleStatus.queryResult?.find((vs) => vs.id === currentVehicleId)}
            energyEarnings={energyEarnings.queryResult}
            range={range}
          />
        ) : (
          <AllVehicles vehicleStatus={vehicleStatus.queryResult} range={range} />
        )}
        {currentVehicleId !== undefined ? (
          <a className="back" href={`#/${currentFleet}`}>
            {I18n.t('solar.dashboard.backToAllVehicles')}
          </a>
        ) : null}
      </div>
    </SelectedDayProvider>
  );
}
