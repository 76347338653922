import React, { useEffect, useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import { HashRouter, Routes, Route, useNavigate, Navigate, Link } from 'react-router-dom';
import { ModalProvider } from './components/modal';
import { AuthProvider, useAuth } from './fetchers/auth';
import I18n from './i18n';
import './index.scss';
import { PrivateRoute } from './privateRoute';
import Dashboard from './pages/dashboard';
import Documents from './pages/documents';
import Faqs from './pages/faqs';
import Fleets from './pages/fleets';
import Login from './pages/login';
import OperatingModes from './pages/operatingModes';
import Users from './pages/users';
import Vehicles from './pages/vehicles';
import InfoBox from './components/infoBox';
import * as routes from './constants/routes';
import { ROLE } from './constants/roles';
function LogoutRoute() {
  const { logout } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    logout();
    navigate('/login', { replace: true });
  }, [logout, navigate]);

  return <></>;
}

function LogInOutLink() {
  const { isLoggedIn } = useAuth();
  return isLoggedIn ? (
    <a href="#/logout" className="logInOut button textbutton">
      {I18n.t('solar.footer.logoutLabel')}
    </a>
  ) : null;
}

function Footer() {
  return (
    <footer>
      <ul className="links">
        <li className="logInOut">
          <LogInOutLink />
        </li>
        <li>
          <a className="button textbutton" href={I18n.t('solar.footer.whisteblowerLink')}>
            {I18n.t('solar.footer.whisteblowerLabel')}
          </a>
        </li>
        <li>
          <a className="button textbutton" href={I18n.t('solar.footer.imprintLink')}>
            {I18n.t('solar.footer.imprintLabel')}
          </a>
        </li>
        <li>
          <a className="button textbutton" href={I18n.t('solar.footer.privacyLink')}>
            {I18n.t('solar.footer.privacyLabel')}
          </a>
        </li>
        <li>
          <a className="button textbutton" href={I18n.t('solar.footer.termsLink')}>
            {I18n.t('solar.footer.termsLabel')}
          </a>
        </li>
      </ul>
    </footer>
  );
}

function LocaleSwitcher({ onLocaleSelected }: { onLocaleSelected: (locale: string) => void }) {
  return (
    <div className="localeSwitcher">
      <span className="language de" onClick={() => onLocaleSelected('de')}>
        DE
      </span>
      <span className="language en" onClick={() => onLocaleSelected('en')}>
        EN
      </span>
    </div>
  );
}

export const AccessDenied = () => {
  return (
    <>
      <div className="modal background" />
      <div className="modal dialog centered networking error">
        <h2>{I18n.t(`solar.errors.access_denied.title`)}</h2>
        <p>{I18n.t(`solar.errors.access_denied.text`)}</p>
        <Link to={routes.HOME}>
          <button>Home</button>
        </Link>
      </div>
    </>
  );
};

function Index() {
  const [, _setLocale] = useState(I18n.currentLocale());

  const setLocale = useCallback((l: string) => {
    I18n.setLocale(l || I18n.currentLocale());
    _setLocale(l || I18n.currentLocale());
  }, []);

  return (
    <>
      <HashRouter>
        <ModalProvider>
          <AuthProvider>
            <LocaleSwitcher onLocaleSelected={setLocale} />
            <Routes>
              <Route path="login" element={<Login />} />
              <Route path="logout" element={<LogoutRoute />} />
              <Route
                path=":fleet/fleets"
                element={
                  <PrivateRoute roles={[ROLE.CAN_VIEW_FLEETS]}>
                    <Fleets />
                  </PrivateRoute>
                }
              />
              <Route
                path=":fleet"
                element={
                  <PrivateRoute roles={[ROLE.CAN_VIEW_DASHBOARD]}>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path=":fleet/details/:vehicle"
                element={
                  <PrivateRoute roles={[ROLE.CAN_VIEW_VEHICLE_DETAILS]}>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path=":fleet/vehicles"
                element={
                  <PrivateRoute roles={[ROLE.CAN_VIEW_VEHICLES]}>
                    <Vehicles />
                  </PrivateRoute>
                }
              />
              <Route
                path=":fleet/operatingModes"
                element={
                  <PrivateRoute roles={[ROLE.CAN_VIEW_OPERATION_MODES]}>
                    <OperatingModes />
                  </PrivateRoute>
                }
              />
              <Route
                path=":fleet/users"
                element={
                  <PrivateRoute roles={[ROLE.CAN_VIEW_USERS]}>
                    <Users />
                  </PrivateRoute>
                }
              />
              <Route
                path=":fleet/reports"
                element={
                  <PrivateRoute roles={[ROLE.CAN_VIEW_DASHBOARD]}>
                    <Documents documentType="reports" />
                  </PrivateRoute>
                }
              />
              <Route
                path=":fleet/docs"
                element={
                  <PrivateRoute roles={[ROLE.CAN_VIEW_DASHBOARD]}>
                    <Documents documentType="docs" />
                  </PrivateRoute>
                }
              />
              <Route
                path=":fleet/faqs"
                element={
                  <PrivateRoute roles={[ROLE.CAN_VIEW_FAQS]}>
                    <Faqs />
                  </PrivateRoute>
                }
              />
              <Route path="/" element={<Navigate replace to={'/*'} />} />
            </Routes>
            <Footer />
          </AuthProvider>
        </ModalProvider>
      </HashRouter>
    </>
  );
}

ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById('root'),
);
