export default {
  "solar": {
    "boolean": {
      "false": "Nein",
      "true": "Ja"
    },
    "cancel": "Abbrechen",
    "dashboard": {
      "allVehicles": {
        "energyEarnings": "Energiegewinn",
        "installedCapacity": "Installierte Leistung",
        "kWInstalled": "kW installiert",
        "kWpInstalled": "kWp installiert",
        "latestSignal": "Letzte Aktualisierung",
        "title": "Alle Fahrzeuge",
        "typeToSearch": "Suchen...",
        "vehicles": "Fahrzeuge",
        "viewDetails": "Details anzeigen"
      },
      "avgPower": "Durchschnittliche Leistung",
      "backToAllVehicles": "Zurück zur gesamten Flotte",
      "dailyAverageEnergyEarnings": "Täglicher durchschnittlicher Energiegewinn",
      "dailyAverageUsage": "Tägliche durchschnittliche Nutzung des PV-Systems",
      "details": {
        "dailyEnergyEarnings": {
          "all": "Alle auswählen",
          "editOperatingMode": "Bearbeiten",
          "filters": {
            "date": "Zeitbereich:",
            "kWh": "Energiegewinn (kWh):",
            "title": "Filter ({{active}} aktiv)",
            "to": "bis"
          },
          "title": "Täglicher Energiegewinn"
        },
        "filters": {
          "addFilter": "Daten am {{day}} als ungültig markieren",
          "editFleetFilter": "Daten am {{day}} sind ungültig für alle Fahrzeuge",
          "editVehicleFilter": "Daten am {{day}} sind ungültig für {{vehicleName}}",
          "fleetFiltered": "Signals sind für alle Fahrzeuge ungültig",
          "markFleetInvalid": "Für alle Fahrzeuge als ungültig markieren",
          "markValid": "Als gültig markieren",
          "markVehicleInvalid": "Für {{vehicleName}} als ungültig markieren",
          "notFiltered": "Signale sind gültig",
          "reason": "Begründung",
          "updateReason": "Begründung aktualisieren",
          "vehicleFiltered": "Signale sind für dieses Fahrzeug ungültig"
        },
        "hourlyOutput": "Energiegewinn (pro Stunde)",
        "hourlyOutputDay": "Tag auswählen:",
        "kWInstalled": "kW installiert",
        "kWpInstalled": "kWp installiert",
        "last30Days": "Täglicher Energiegewinn",
        "latestSignal": "Letzte Aktualisierung",
        "operatingMode": {
          "reason": "Grund / Kommentar (optional)",
          "title": "Betriebsmodus für {{vehicleName}} am {{day}}",
          "titleMultipleDays": "Betriebsmodus für {{vehicleName}} an ausgewählten Tagen"
        },
        "title": "Details"
      },
      "downloadCsv": {
        "day": "Tag",
        "energyEarningsWh": "Energiegewinn (Wh)",
        "label": "CSV",
        "tooltip": "Ausgewählte Werte als CSV-Datei herunterladen"
      },
      "duration": {
        "allTime": "Gesamt",
        "month": "Monat",
        "title": "Zeitraum:",
        "year": "Jahr"
      },
      "energy": "Energie",
      "excluded": "Nicht in der Statistik berücksichtigt",
      "hours": "Stunden",
      "includedOperatingModes": "Betriebszustände:",
      "kWh": "kWh",
      "monthlyAverageEnergyEarnings": "Monatlicher durchschnittlicher Energiegewinn",
      "noVehicles": "Diese Flotte beinhaltet noch keine Fahrzeuge.",
      "peakEnergyEarnings": "Maximaler Energiegewinn",
      "summary": "Zusammenfassung",
      "title": "Dashboard",
      "totalEnergyEarnings": "Gesamter Energiegewinn",
      "vehicles": "Fahrzeuge",
      "weeklyAverageEnergyEarnings": "Wöchentlicher durchschnittlicher Energiegewinn"
    },
    "date": "{{day}}.{{month}}.{{year}}",
    "documents": {
      "backToDashboard": "Zurück zum Dashboard",
      "docs": {
        "empty": "Es gibt bisher keine Dokumentation.",
        "title": "Dokumentation"
      },
      "reports": {
        "empty": "Es gibt bisher keine Reports.",
        "title": "Reports"
      }
    },
    "errors": {
      "confirm": "Ok",
      "errorLoadingData": {
        "text": "Hoppla! Es ist ein unerwarteter Fehler beim Laden der Seite aufgetreten. Bitte versuche es erneut.",
        "title": "Fehler beim Laden"
      },
      "errorSavingData": {
        "text": "Hoppla! Deine Daten konnten leider nicht gespeichert werden. Bitte versuche es erneut.",
        "title": "Fehler beim Speichern"
      },
      "loginError": {
        "text": "Hm, der Login ist leider fehlgeschlagen. Bitte versuche es erneut.",
        "title": "Fehler beim Login"
      },
      "showDetails": "Details anzeigen",
      "access_denied": {
        "title": "Kein Zugriff verfügbar",
        "text": "Sie haben keinen Zugriff für diese Seite."
      }
    },
    "euros": {
      "delimiter": ".",
      "format": "{{amount}} €",
      "separator": ","
    },
    "faqs": {
      "edit": {
        "answer": "Antwort ({{lang}})",
        "deleteButton": "Löschen",
        "editGroup": "Gruppe bearbeiten",
        "editQuestion": "Frage bearbeiten",
        "groupTitle": "Titel ({{lang}})",
        "question": "Frage ({{lang}})"
      },
      "intro": "Wir haben hier die wichtigsten Informationen zusammengefasst. Wir befinden uns noch mitten in der Entwicklung und bitten daher um Verständnis, dass sich Details noch ändern können.",
      "title": "Häufige Fragen"
    },
    "fleet": {
      "add": "Neue Flotte",
      "addButton": "Neu",
      "deleteButton": "Löschen",
      "editButton": "Bearbeiten",
      "fleets": {
        "add": "Neue Flotte",
        "edit": "Flotte bearbeiten",
        "fleetType": "Typ",
        "fleetTypeBusKit": "Bus-Kit",
        "fleetTypeCampingworld": "Campingworld",
        "fleetTypeSvc3": "SVC3",
        "name": "Name",
        "slug": "Bezeichner",
        "title": "Flotten"
      },
      "name": "Name",
      "operatingModes": {
        "color": "Farbe",
        "edit": "Betriebsmodus bearbeiten",
        "isDefault": "Standard?",
        "name": "Name",
        "title": "Betriebszustände",
        "useForStatistics": "In Statistiken verwenden?"
      },
      "slug": "Id",
      "users": {
        "add": "Neuer Benutzer",
        "edit": "Benutzer bearbeiten",
        "email": "E-Mail",
        "role": "Rolle",
        "role_admin": "Admin",
        "role_viewer": "Anzeigen",
        "title": "Benutzer"
      },
      "vehicles": {
        "add": "Neues Fahrzeug",
        "deviceIds": "Device-IDs",
        "deviceIdsExample": "z.B. 5E883901, 7A046BC1",
        "edit": "Fahrzeug bearbeiten",
        "installedCapacity": "Installierte Leistung",
        "installedCapacitykW": "Installierte Leistung (kW)",
        "installedCapacitykWp": "Installierte Leistung (kWp)",
        "location": "Ort",
        "model": "Model",
        "name": "Name",
        "numberPlate": "Kennzeichen",
        "operationMode": "Betriebsmodus",
        "route": "Route",
        "timeZone": "Zeitzone",
        "title": "Fahrzeuge",
        "validFrom": "Gültige Daten von",
        "validFromDay": "von {{from}}",
        "validity": "Datengültigkeit",
        "validUntil": "Gültige Daten bis",
        "validUntilDay": "bis {{until}}",
        "vehicleType": "Fahrzeugtyp",
        "installMetaDataType": "Installierter Typ",
        "installMetaDataMCUVersion": "Installierte MCU Version",
        "installMetaDataState": "Instellation Status",
      }
    },
    "footer": {
      "imprintLabel": "Impressum",
      "imprintLink": "https://sonomotors.com/de/imprint/",
      "logoutLabel": "Logout",
      "privacyLabel": "Datenschutzerklärung",
      "privacyLink": "https://sonomotors.com/de/privacy-policy/",
      "termsLabel": "AGB und Widerrufsrecht",
      "termsLink": "https://sonomotors.com/de/downloads/terms-and-conditions/",
      "whisteblowerLabel": "Whistleblower Hotline",
      "whisteblowerLink": "https://www.whistleblowerservices.com/sonogroup?language=de"
    },
    "login": {
      "emailAddress": "E-Mail-Adresse",
      "login": "Login",
      "loginCode": "Login-Code",
      "loginInstructions": "Um dich einzuloggen, gibst du nachstehend ganz einfach deine E-Mail-Adresse ein. Wir schicken dir dann umgehend einen Login-Link.",
      "loginLinkRequestedText": "Wir haben dir gerade eine E-Mail an {{email}} geschickt. Benutze den Link aus der E-Mail oder gib den Code hier ein um dich einzuloggen:",
      "loginLinkRequestedTitle": "Du hast Post!",
      "loginWithEmail": "Einloggen mit E-Mail",
      "pageTitle": "Login",
      "requestAgain": "Erneut anfordern",
      "requestLoginLink": "Login-Link anfordern",
      "wrongCode": "Verflixt, der eingegebene Code scheint nicht zu stimmen. Bitte versuche es erneut oder fordere einen neuen Code an.",
      "yourSonoAccount": "Dein Sono-Account"
    },
    "noFleets": {
      "text": "Du bist für keine Flotte freigeschaltet. Bitte prüfe, ob du die richtige E-Mail-Adresse verwendet hast oder bitte deine:n Flottenadministrator:in, dich hinzuzufügen.",
      "title": "Hier gibt es nichts zu sehen"
    },
    "ok": "Ok"
  }
};